<template>
  <b-card>
    <h4 class="mb-0">
      Detail Data Orang Tua
    </h4>
    <validation-observer ref="validUpdate">
      <b-form class="mt-1" @submit.prevent="validationForm">
        <b-row>
          <b-col md="6">

            <validation-provider #default="{ errors }" name="student_nisn" rules="required">
              <b-form-group label="Siswa" label-for="student_nisn" :state="errors.length > 0 ? false : null">
                <v-select id="student_id" v-model="dataStudent.student_nisn" :reduce="(student_nisn) => student_nisn.nisn"
                  placeholder="Pilih Siswa" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="listSiswa"
                  label="name" disabled />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <validation-provider #default="{ errors }" name="relationship" rules="required">
              <b-form-group label="Hubungan Orang Tua" label-for="relationship" :state="errors.length > 0 ? false : null">
                <v-select id="nisn" v-model="dataStudent.relationship" :reduce="(relationship) => relationship.real_id"
                  placeholder="Pilih Status Orang Tua" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="listRelationship" label="relationship_status_desc" disabled />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider #default="{ errors }" name="education_level_id" rules="required">
              <b-form-group label="Pendidikan Orang Tua" label-for="education_level_id"
                :state="errors.length > 0 ? false : null">
                <v-select id="education_level_id" v-model="dataStudent.education_level_id"
                  :reduce="(education_level_id) => education_level_id.real_id" placeholder="Pilih Pendidikan Orang Tua"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="listEducation"
                  label="level_education_desc" disabled />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>


            <b-form-group label="Nama Orang Tua" label-for="name">
              <validation-provider #default="{ errors }" name="Nama Orang Tua" rules="required">
                <b-form-input id="name" v-model="dataStudent.name" :state="errors.length > 0 ? false : null"
                  placeholder="Nama orang Tua" disabled />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Tempat Lahir" label-for="birth_place">
              <validation-provider #default="{ errors }" name="Tempat Lahir" rules="required">
                <b-form-input id="birth_place" v-model="dataStudent.birth_place" :state="errors.length > 0 ? false : null"
                  placeholder="Yogyakarta" disabled />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Tanggal Lahir" label-for="birth_date">
              <validation-provider #default="{ errors }" name="Tanggal Lahir" rules="required">
                <b-form-input id="birth_date" v-model="dataStudent.birth_date" :state="errors.length > 0 ? false : null"
                  placeholder="Tanggal Lahir" type="date" disabled />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Agama" label-for="religion">
              <validation-provider #default="{ errors }" name="Agama" rules="required">
                <v-select id="religion" v-model="dataStudent.religion" :reduce="(religion) => religion.value"
                  :options="dataReligion" label="text" placeholder="Pilih Agama" disabled />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Kewarganegaraan" label-for="nationality">
              <validation-provider #default="{ errors }" name="Kewarganegaraan" rules="required">
                <v-select id="nationality" v-model="dataStudent.nationality" :reduce="(nationality) => nationality.name"
                  :options="dataCountries" label="name" placeholder="Pilih Kewarganegaraan" disabled />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Program Studi" label-for="education_major">
              <validation-provider #default="{ errors }" name="Program Studi" rules="required">
                <b-form-input id="education_major" v-model="dataStudent.education_major"
                  :state="errors.length > 0 ? false : null" placeholder="Program Studi" type="text" disabled />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <validation-provider #default="{ errors }" name="profession" rules="required">
              <b-form-group label="Pekerjaan" label-for="profession" :state="errors.length > 0 ? false : null">
                <v-select id="profession" v-model="dataStudent.profession" :reduce="(profession) => profession.real_id"
                  placeholder="Pilih Pekerjaan" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="listPekerjaan" label="working_description" disabled />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <validation-provider #default="{ errors }" name="salary" rules="required">
              <b-form-group label="Penghasilan" label-for="salary" :state="errors.length > 0 ? false : null">
                <v-select id="salary" v-model="dataStudent.salary" :reduce="(salary) => salary.real_id"
                  placeholder="Pilih Penghasilan" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="listSalary" label="range" disabled />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

          </b-col>
          <b-col md="6">
            <validation-provider #default="{ errors }" name="Provinsi" rules="required">
              <b-form-group label="Provinsi" label-for="province" :state="errors.length > 0 ? false : null">
                <v-select id="province" v-model="locations.province" placeholder="Pilih Provinsi"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="dataProvinces" label="name" disabled />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- <b-form-group label="Provinsi" label-for="province">
              <validation-provider #default="{ errors }" name="province" rules="required">
                <v-select id="province" v-model="locations.province" :reduce="(province) => province.name"
                  :options="dataProvinces" label="name" placeholder="Provinsi" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group> -->
            <b-form-group label="Kabupaten / Kota" label-for="city">
              <validation-provider #default="{ errors }" name="city" rules="required">
                <v-select id="city" v-model="locations.city" :reduce="(city) => city.name" :options="filterCities"
                  label="name" placeholder="Kabuapten / Kota" disabled />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Kecamatan" label-for="district">
              <validation-provider #default="{ errors }" name="district" rules="required">
                <v-select id="district" v-model="locations.district" :reduce="(district) => district.name"
                  :options="filterDistrics" label="name" placeholder="Kecamatan" disabled />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>

            <!-- <b-form-group label="Kelurahan" label-for="subdistrict">
              <validation-provider #default="{ errors }" name="subdistrict" rules="required">
                <v-select id="subdistrict" v-model="locations.subdistrict" :reduce="(subdistrict) => subdistrict.name"
                  :options="filterSubDistrics" label="name" placeholder="Kelurahan" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>

            <validation-provider #default="{ errors }" name="subdistrict" rules="required">
              <b-form-group label="Kelurahan" label-for="subdistrict" :state="errors.length > 0 ? false : null">
                <v-select id="subdistrict" v-model="locations.subdistrict" placeholder="Kelurahan"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="filterSubDistrics" label="name" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider> -->

            <validation-provider #default="{ errors }" name="subdistrict" rules="required">
              <b-form-group label="Kelurahan/Desa" label-for="subdistrict" :state="errors.length > 0 ? false : null">
                <v-select id="subdistrict" v-model="locations.subdistrict" placeholder="Pilih Kelurahan/Desa"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="filterSubDistrics" label="name"
                  disabled />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <b-form-group label="RW" label-for="rw">
              <validation-provider #default="{ errors }" name="RW" rules="required">
                <b-form-input id="rw" v-model="dataStudent.rw" :state="errors.length > 0 ? false : null" placeholder="RW"
                  disabled />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="RT" label-for="rt">
              <validation-provider #default="{ errors }" name="RT" rules="required">
                <b-form-input id="rt" v-model="dataStudent.rt" :state="errors.length > 0 ? false : null" placeholder="RT"
                  disabled />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Alamat" label-for="address">
              <validation-provider #default="{ errors }" name="Alamat" rules="required">
                <b-form-input id="address" v-model="dataStudent.address" :state="errors.length > 0 ? false : null"
                  placeholder="Jl. Kaliurang Km.07 B12" disabled />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <validation-provider #default="{ errors }" name="parental_status" rules="required">
              <b-form-group label="Status Orang Tua" label-for="parental_status"
                :state="errors.length > 0 ? false : null">
                <v-select id="parental_status" v-model="dataStudent.parental_status"
                  :reduce="(parental_status) => parental_status.real_id" placeholder="Pilih Status dalam keluarga"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="listStatusOrangTua"
                  label="parent_statuses_desc" disabled />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <b-form-group label="No HP" label-for="handphone">
              <validation-provider #default="{ errors }" name="handphone" rules="required">
                <b-form-input id="handphone" v-model="dataStudent.handphone" :state="errors.length > 0 ? false : null"
                  placeholder="081xxxxxxxx" type="text" disabled />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Email" label-for="email">
              <validation-provider #default="{ errors }" name="Email" rules="required">
                <b-form-input id="email" v-model="dataStudent.email" :state="errors.length > 0 ? false : null"
                  placeholder="email@email.com" type="email" disabled />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="NIK" label-for="nik">
              <validation-provider #default="{ errors }" name="nik" rules="required">
                <b-form-input id="nik" v-model="dataStudent.nik" :state="errors.length > 0 ? false : null"
                  placeholder="3601xxxxxxxx" type="number" disabled />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

          </b-col>
        </b-row>
        <b-row class="text-right">
          <!-- submit and reset -->
          <b-col>
            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="reset" variant="outline-secondary" class="mr-1"
              @click="() => $router.go(-1)">
              Back
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  // BContainer,
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  // BFormCheckbox,
  BFormInvalidFeedback,
  BForm,
  BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import dataCountries from '@/assets/json/countries.json'
import dataProvinces from '@/assets/json/provinces.json'
import dataCities from '@/assets/json/cities.json'
import dataDistrics from '@/assets/json/districts.json'
import dataSubDistrics from '@/assets/json/subdistricts.json'

export default {
  name: 'DataOrangtuaEdit',
  components: {
    ValidationProvider,
    ValidationObserver,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    // BContainer,
    // BFormCheckbox,
    BForm,
    BButton,
    vSelect,
    BFormInvalidFeedback,
  },
  directives: {
    Ripple,
  },
  data: () => ({
    dataCountries,
    dataProvinces,
    dataCities,
    dataDistrics,
    dataSubDistrics,
    required,
    dataStudent: {
      student_nisn: '',
      name: '',
      birth_place: '',
      birth_date: '',
      gender: '',
      religion: '',
      nationality: '',
      relationship: '',
      address: '',
      distance: '',
      education_level_id: '',
      handphone: '',
      email: '',
      nik: '',
      rt: '',
      rw: '',
      education_major: '',
      profession: '',
      salary: '',
      parental_status: '',
      _method: 'PUT'
    },
    locations: {
      province: '',
      city: '',
      district: '',
      subdistrict: '',
    },
    dataGender: [
      { value: 'M', text: 'Laki-laki' },
      { value: 'F', text: 'Perempuan' },
    ],
    dataReligion: [
      { value: 1, text: 'Islam' },
      { value: 2, text: 'Protestan' },
      { value: 3, text: 'Katolik' },
      { value: 4, text: 'Hindu' },
      { value: 5, text: 'Buddha' },
      { value: 6, text: 'Khonghucu' },
    ],
    listSiswa: [],
    listPekerjaan: [],
    listSalary: [],
    listEducation: [],
    listRelationship: [],
    listStatusOrangTua: []
  }),
  computed: {
    // filterCities() {
    //   const findCity = this.dataCities.filter(
    //     city => city.name === this.locations.city,
    //   )
    //   console.log(findCity[0])
    //   return this.dataCities.filter(
    //     item => item.parent === this.locations.province.wilayah_id,
    //   )
    // },
    // filterDistrics() {
    //   console.log('District');
    //   return this.dataDistrics.filter(
    //     item => item.parent === this.locations.city.wilayah_id,
    //   )
    // },
    // filterSubDistrics() {
    //   return this.dataSubDistrics.filter(
    //     item => item.parent === this.locations.distric.wilayah_id,
    //   )
    // }    

    filterCities() {
      return this.dataCities.filter(
        item => item.parent === this.locations.province.wilayah_id,
      )
    },
    filterDistrics() {
      return this.dataDistrics.filter(
        item => item.parent === this.locations.city.wilayah_id,
      )
    },
    filterSubDistrics() {
      return this.dataSubDistrics.filter(
        item => item.parent === this.locations.district.wilayah_id,
      )
    },
  },
  created() {
    this.getStudentDetail(),
      this.getSiswa(),
      this.getPekerjaan(),
      this.getSalary(),
      this.getEducation(),
      this.getrelationship(),
      this.getstatus()
  },
  methods: {
    async getSiswa() {
      try {
        const response = await this.$http.get('/students')
        this.listSiswa = response.data.data
      } catch (err) {
        this.showToast('danger', 'top-center', 'Get Data Not Success')
        console.log(err)
      }
    },
    async getPekerjaan() {
      try {
        const response = await this.$http.get('/works')
        this.listPekerjaan = response.data.data
      } catch (err) {
        this.showToast('danger', 'top-center', 'Get Data Not Success')
        console.log(err)
      }
    },
    async getSalary() {
      try {
        const response = await this.$http.get('/salaryranges')
        this.listSalary = response.data.data
      } catch (err) {
        this.showToast('danger', 'top-center', 'Get Data Not Success')
        console.log(err)
      }
    },
    async getEducation() {
      try {
        const response = await this.$http.get('/leveleducations')
        this.listEducation = response.data.data
      } catch (err) {
        this.showToast('danger', 'top-center', 'Get Data Not Success')
        console.log(err)
      }
    },
    async getrelationship() {
      try {
        const response = await this.$http.get('/relationshipstatuses')
        this.listRelationship = response.data.data
      } catch (err) {
        this.showToast('danger', 'top-center', 'Get Data Not Success')
        console.log(err)
      }
    },
    async getstatus() {
      try {
        const response = await this.$http.get('/parentstatuses')
        this.listStatusOrangTua = response.data.data
      } catch (err) {
        this.showToast('danger', 'top-center', 'Get Data Not Success')
        console.log(err)
      }
    },
    async getStudentDetail() {
      try {
        const studentId = this.$route.params.id
        const response = await this.$http.get(`/studentfamilies/${studentId}`)
        this.fillStudentData(response.data.data)
      } catch (err) {
        console.log(err)
      }
    },
    fillStudentData(data) {
      this.dataStudent.name = data.name
      this.dataStudent.student_nisn = data.student_nisn
      this.dataStudent.birth_place = data.birth_place
      this.dataStudent.birth_date = data.birth_date.substr(0, 10)
      this.dataStudent.gender = data.gender
      this.dataStudent.religion = data.religion
      this.dataStudent.nationality = data.nationality
      this.dataStudent.relationship = data.relationship.id
      this.dataStudent.address = data.address
      this.dataStudent.distance = data.distance
      this.dataStudent.handphone = data.handphone
      this.dataStudent.email = data.email
      this.dataStudent.nik = data.nik
      this.dataStudent.rt = data.rt
      this.dataStudent.rw = data.rw
      this.dataStudent.education_major = data.education_major
      this.dataStudent.profession = data.profession
      this.dataStudent.salary = data.salary
      this.dataStudent.education_level_id = data.education_level_id
      this.dataStudent.parental_status = data.parental_status

      this.locations.province = data.province
      this.locations.city = data.city
      this.locations.district = data.district
      this.locations.subdistrict = data.subdistrict
    },
    validationForm() {
      this.$refs.validUpdate.validate().then(async success => {
        if (success) {
          // eslint-disable-next-line
          try {
            const studentId = this.$route.params.id
            const dataFormStudent = new FormData()
            Object.keys(this.dataStudent).forEach(key => {
              dataFormStudent.append(key, this.dataStudent[key])
            })
            Object.keys(this.locations).forEach(key => {
              dataFormStudent.append(key, this.locations[key])
            })
            dataFormStudent.append('_method', 'PUT')
            await this.$http.post(`/studentfamilies/${studentId}`, dataFormStudent, {
              headers: {
                'content-type': 'multipart/form-data',
              },
            })
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Tambah Data Berhasil',
                icon: 'AlertCircleIcon',
                variant: 'success',
              },
            })
            this.$router.push({ name: 'data-orangtua' })
          } catch (err) {
            console.log(err)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Tambah Data Tidak Berhasil',
                icon: 'AlertCircleIcon',
                variant: 'danger',
              },
            })
            console.log(err)
          }
        }

        // function alertnya() {
        //   alert('form submitted!')
        // }
      })
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/vue/libs/vue-select.scss";
</style>
